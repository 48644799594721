import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlLoggedInUserService, XpoLtlReleaseNotesService } from '@xpo-ltl/ngx-ltl';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';
import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { BehaviorSubject, interval } from 'rxjs';
import { delay, filter, retryWhen, skipWhile, take, tap } from 'rxjs/operators';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { AppTitle } from './shared/enums/app-title.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { UserPermission } from './shared/guards/permission/permission.enum';
import { UserPermissionService } from './shared/services/user/user-permission.services';
import { UserService } from './shared/services/user/user.services';

interface XpoShellRouteSecure extends XpoShellRoute {
  permission?: UserPermission;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title: string;
  build: string;
  routes: Array<any> = [
    {
      label: 'List',
      path: `/${AppRoutes.TARIFF_LIST}`,
      permission: UserPermission.tariffList,
    },
    {
      label: 'Get rate blocks',
      path: `/${AppRoutes.TARIFF_DETAIL}`,
      permission: UserPermission.tariffDetail,
    },
    {
      label: 'Transit Time File Request',
      path: `/${AppRoutes.TRANSIT_TIME_FILE_REQUEST}`,
      permission: UserPermission.transitTimeFile,
    },
    {
      label: 'Rate File Request',
      path: `/${AppRoutes.RATE_FILE_REQUEST}/${AppRoutes.FILE}`,
      permission: UserPermission.rateFile,
    },
  ];
  email: string = 'ITFreightPricingRatingSupport@xpo.com';
  notifications = [{ title: 'Important notification 1', url: 'https://tcts3-app.ltl.xpo.com/appjs/ngx-ltl-core-docs' }];

  userRoutes = new BehaviorSubject<XpoShellRoute[]>([]);
  userRoutes$ = this.userRoutes.asObservable();
  private readonly currentUserSubject = new BehaviorSubject<User>(undefined);

  readonly AccountUrls = AccountUrls;

  constructor(
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    private releaseNotesService: XpoLtlReleaseNotesService,
    private loginService: XpoLtlLoggedInUserService,
    private userService: UserService,
    private userPermissionService: UserPermissionService,
    private router: Router
  ) {
    /** Shell setup */
    this.title = AppTitle.tariffManagement;
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
      // Extra config or API calls here if needed
    });
    this.handleLoggedInUser();
    this.loginService.userLoggedIn$.subscribe(() => {
      this.handleLoggedInUser();
    });
    this.verifyPermissions();
  }

  private getUserRoutes(routes: XpoShellRouteSecure[], permissions: Array<string>): XpoShellRoute[] {
    const result = routes
      .filter((route: XpoShellRouteSecure) => {
        return !route.permission || permissions.includes(route.permission) ? true : false;
      })
      .map((route: XpoShellRouteSecure) => {
        return {
          label: route.label,
          path: route.path,
        };
      });

    return result;
  }

  ngOnInit(): void { }

  verifyPermissions(): void {
    this.userPermissionService.userPermissions$
      .pipe(filter((permissions) => permissions !== undefined))
      .subscribe((permissions) => {
        const hasPermissions = permissions && permissions.length > 0 ? true : false;
        if (hasPermissions) {
          const permissionsBasedRoutes = this.getUserRoutes(this.routes, permissions);
          this.userRoutes.next(permissionsBasedRoutes);
          if (this.router.url === AppRoutes.ROOT) {
            if (permissions.includes(UserPermission.tariffList)) {
              this.router.navigate([`${AppRoutes.TARIFF_LIST}`]);
            } else if (permissions.includes(UserPermission.tariffDetail)) {
              this.router.navigate([`${AppRoutes.TARIFF_DETAIL}`]);
            }
          }
        }
      });
  }

  private handleLoggedInUser(): void {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(
        retryWhen((errors) => {
          return errors.pipe(delay(1000), take(5));
        })
      )
      .subscribe(
        (user: User) => {
          if (user) {
            this.userService.userSource.next(user);
            this.setDynatraceUserIdentity(user);
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  /*
    handleFeedbackClick(): void {
      this.feedbackService.feedbackConfig$.pipe(take(1)).subscribe((config) => {
        this.dialog.open(XpoFeedback, { data: config });
      });
    }
  */

  handleReleaseNotesClick(): void {
    this.releaseNotesService.showReleaseNotes().subscribe(() => { });
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(window['dtrum'], 'identifyUser', !_isEmpty(user.emailAddress) ? user.emailAddress : user.userId);
    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }
}
